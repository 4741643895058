import { 
	DesignIndex, 
	DesginOrder, 
	DesignCase, 
	CreditCard, 
	DesignPrices, 
	CreditCardList, 
	BillContactList, 
	DesignTerms, 
	OrderDetail,
	DesignAccept,
	DesignPreference,
	DesignAnnouncement
} from '@shining3d/xl-design-order';

export default [
	{
		path: '/u/design',
		name: 'design',
		component: DesignIndex,
		children: [
			{
				path: '',
				name: 'designhome',
				component: DesignCase
			},
			{
				path: '/u/design/case',
				name: 'designCase',
				component: DesignCase
			},
			{
				path: '/u/design/order',
				name: 'designOrder',
				component: DesginOrder,
				// meta: { keepAlive: true }
			},
			{
				path: '/u/design/order/accept/:id',
				name: 'designAccept',
				component: DesignAccept
			},
			{
				path: '/u/design/orderDetail/:id',
				name: 'orderDetail',
				component: OrderDetail
			},
			{
				path: '/u/design/prices',
				name: 'designPrices',
				component: DesignPrices
			},
			{
				path: '/u/design/terms',
				name: 'designTerms',
				component: DesignTerms
			},
			{
				path: '/u/design/preference',
				name: 'designPreference',
				component: DesignPreference
			},
			{
				path: '/u/design/announcement',
				name: 'designAnnouncement',
				component: DesignAnnouncement
			}
		],
		redirect: '/u/design/case'
	},
	{
		path: '/u/creditCard',
		name: 'creditCard',
		component: CreditCard
	},
	{
		path: '/u/creditCard/creditCardList',
		name: 'creditCardList',
		component: CreditCardList
	},
	{
		path: '/u/creditCard/billContactList',
		name: 'billContactList',
		component: BillContactList
	}
];
