import http from '@/utils/http';
const rootUrl = '/api';

const Patient = {
	/**
	 * 获取患者报告
	 * @returns
	 */
	getPatientReports( params ) {
		return http.get( `${rootUrl}/dentalPatientReportList`, { params });
	},
	/**
	 * 获取患者列表
	 * @param {*} params
	 * @returns
	 */
	getPatients( params ) {
		return http.get( `${rootUrl}/dentalPatientListV2`, { params });
	},
	/**
	 * 获取患者未脱敏的原始值
	 * @param {*} params
	 * @returns
	 */
	getPatientOriginalData( params ) {
		return http.get( `${rootUrl}/getPatientFieldRealInfo`, { params });
	},
	/**
	 * 新增患者
	 * @param {*} params
	 */
	addPatient( data ) {
		return http.post( `${rootUrl}/dentalPatientAddV2`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 患者详情
	 * @param {*} params
	 * @returns
	 */
	getPatient( params ) {
		return http.get( `${rootUrl}/dentalPatientDetail`, { params });
	},
	/**
	 * 修改患者信息
	 * @param {*} data
	 * @returns
	 */
	editPatient( data ) {
		return http.post( `${rootUrl}/dentalPatientUpdateV2`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取相似患者
	 * @param {*} params
	 * @returns
	 */
	getSimilarPatients( params ) {
		return http.get( `${rootUrl}/patientMergeList`, { params });
	},
	/**
	 * 合并患者
	 * @param {*} data
	 * @returns
	 */
	mergePatients( data ) {
		return http.post( `${rootUrl}/patientMerge`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取就诊记录
	 * @param {*} params
	 * @returns
	 */
	getMedicalRecords( params ) {
		return http.get( `${rootUrl}/MedicalRecords`, { params });
	},
	/**
	 * 获取就诊记录列表
	 * @param {*} params
	 * @returns
	 */
	getMedicalRecordsList( params ) {
		return http.get( `${rootUrl}/medicalRecordList`, { params });
	},
	/**
	 * 设置就诊记录的名称
	 * @param {*} data
	 * @returns
	 */
	setMedicalRecordName( data ) {
		return http.post( `${rootUrl}/medicalRecordUpdate`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取主治医生
	 * @param {*} params
	 * @returns
	 */
	getDoctors( params ) {
		return http.get( `${rootUrl}/getDoctors`, { params });
	},
	/**
	 * 上传影像集
	 * @param {*} data
	 * @returns
	 */
	uploadIct( data ) {
		return http.post( `${rootUrl}/patientUploadMedicalRecordFile`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取医生列表
	 * @param {*} params
	 * @returns
	 */
	getDoctorList( params ) {
		return http.get( `${rootUrl}/getDoctors`, { params });
	},
	/**
	 * 获取影像集列表
	 * @param {*} params
	 * @returns
	 */
	getIctList( params ) {
		return http.get( `${rootUrl}/ictList`, { params });
	},
	/**
	 * 影像集预览文件过期后手动触发重新转换接口
	 * @param {*} data
	 * @returns
	 */
	 reconversion( data ) {
		return http.post( `${rootUrl}/reconversionMedicalRecord`, {
			data,
			errCode: 'patient'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 批量解冻、转换归档文件
	 * @param {*} data
	 * @returns
	 */
	batchRestoreAttach( data ) {
		return http.post( `${rootUrl}/batchRestoreAttach`, {
			data,
			errCode: 'coldArchive'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 影像集/订单文件权限检查
	 * @param {*} data
	 * @returns
	 */
	checkDeviceFileAuth( data ) {
		return http.post( `${rootUrl}/checkFileDeviceAuth `, { data, errCode: 'patient.checkDeviceFileAuth' }, { headers: { isCsrf: true } });
	},
	/**
	 * 家庭关系网成员列表
	 * @param {*} data
	 * @returns
	 */
	getFamilyMember( params ) {
		return http.get( `${rootUrl}/familyMemberList`, { params, errCode: 'patient.getFamilyMember' });
	},
	/**
	 * 家庭关系网列表
	 * @param {*} data
	 * @returns
	 */
	getFamilyList( params ) {
		return http.get( `${rootUrl}/familyList`, { params, errCode: 'patient.getFamilyList' });
	},
	/**
	 * 家庭关系网添加成员
	 * @param {*} data
	 * @returns
	 */
	familyAdd( data ) {
		return http.post( `${rootUrl}/familyAddMember`, { data, errCode: 'patient.familyAdd' }, { headers: { isCsrf: true } });
	},
	/**
	 * 家庭关系网删除成员
	 * @param {*} data
	 * @returns
	 */
	familyDel( data ) {
		return http.post( `${rootUrl}/familyDelMember`, { data, errCode: 'patient.familyDel' }, { headers: { isCsrf: true } });
	},
	/**
	 * 家庭关系网患者搜索
	 * @param {*} data
	 * @returns
	 */
	familyPatientSearch( params ) {
		return http.get( `${rootUrl}/familyPatientSearch`, { params, errCode: 'patient.familyPatientSearch' });
	},
	/**
	 * 齿科云平台-患者管理-测量报告
	 * @param {*} data
	 * @returns
	 */
	fetchMeasurementReportList( params ) {
		return http.get( `${rootUrl}/fetchMeasurementReportList`, { params });
	},
	/**
	 * 报告发送短信、邮件
	 * @param {*} data
	 * @returns
	 */
	 sendReportMailSms( data ) {
		return http.post( `${rootUrl}/sendReportMailSms`, { data, errCode: 'patient.familyDel' }, { headers: { isCsrf: true } });
	},
	/**
	 * 批量导入患者
	 * @param {*} data
	 * @returns
	 */
	dentalPatientBatchInput( data ) {
		return http.post( `${rootUrl}/dentalPatientBatchInput`, { data, errCode: 'patient' }, { headers: { isCsrf: true } });
	},
	/**
	 * 齿科云平台-新增患者-获取患者必填信息
	 * @param { factoryID: string }
	 * @returns
	 */
	getPatientRecordRequireInfo( params ) {
		return http.get( `${rootUrl}/getPatientRecordRequireInfo`, { params });
	},
	/**
	 * 手动模型比对
	 * @param {*} data
	 * @returns
	 */
	teethModelComparison( data ) {
		return http.post( `${rootUrl}/teethModelComparison`, { data, errCode: 'patient' }, { headers: { isCsrf: true } });
	},
	/**
	 * 牙弓编辑
	 * @param {*} data
	 * @returns
	 */
	editTeethArch( data ) {
		return http.post( `${rootUrl}/editTeethArch`, { data, errCode: 'patient' }, { headers: { isCsrf: true } });
	},
	/**
	 * 编辑拥挤度rt信息
	 * @param {*} data
	 * @returns
	 */
	editTeethRT( data ) {
		return http.post( `${rootUrl}/editTeethRT`, { data, errCode: 'patient' }, { headers: { isCsrf: true } });
	},
	/**
	 * 牙齿测量数据保存
	 * @param {*} data
	 * @returns
	 */
	saveGaugeData( data ) {
		return http.post( `${rootUrl}/saveGaugeData`, { data, errCode: 'patient' }, { headers: { isCsrf: true } });
	},
	/**
	 * 患者管理-模型测量-开始分牙
	 * @param {recordID: "xxx",hospitalID: "wasa"}
	 * @returns
	 */
	markTeeth( data ){
		return http.post( `${rootUrl}/teethSeparation`, { data, errCode: 'common.postErr' }, { headers: { isCsrf: true } }); 
	},
	/**
	 * 患者管理-模型测量-分牙-保存（保存牙号）
	 * @param {*} data
	 * @returns
	 */
	editTeethPosition( data ){
		return http.post( `${rootUrl}/editTeethPosition`, { data, errCode: 'common.postErr' }, { headers: { isCsrf: true } }); 
	},
	/**
	 * 患者管理-模型测量-设置-获取模版
	 * @param { orgID: string }
	 * @returns
	 */
	getOrgTeethCongestionConfig( params ){
		return http.get( `${rootUrl}/getOrgTeethCongestionConfig`, { params, errCode: 'modelGauge.backenErr' }); 
	},
	/**
	 * 患者管理-模型测量-设置-编辑模版
	 * @param {recordID: "xxx",hospitalID: "wasa"，congestion:"牙齿拥挤度"}
	 * @returns
	*/
	editOrgTeethCongestion( data ){
		return http.post( `${rootUrl}/editOrgTeethCongestion`, { data, errCode: 'common.postErr' }, { headers: { isCsrf: true } }); 
	},
	/**
	 * 患者管理-模型测量-分牙-区域编辑
	 * @param {*} data
	 * @returns
	 */
	editTeethArea( data ){
		return http.post( `${rootUrl}/editTeethArea`, { data, errCode: 'common.postErr' }, { headers: { isCsrf: true } }); 
	},
};
export default Patient;