import { DoctorConfirm } from '@shining3d/xl-design-order';

export default [
    {
        path: '/doctorConfirm/approve/:id',
        name: 'doctorConfirmApprove',
        component: DoctorConfirm,
        meta: {
            noAuth: true
        }
    }
];
