import { $exitBySearch } from '@/assets/common/common.js';
import $store from '@/store';
import info from '@/views/info/router';
import design from '@/views/design/router';
import pubLay from '@/views/pub/index.vue';
import pub from '@/views/pub/pub';
import DoctorConfirm from '@/views/doctorConfirm/router.js';
import reportNew from '@/views/reports/router';
import _get from 'lodash/get';
import { nextTick } from 'vue';
import { XlMessageBox } from '@shining3d/ui-components';
import { useLang } from '@/hooks/lang';
import { VueCookieNext } from 'vue-cookie-next';
import { createRouter, createWebHistory } from 'vue-router';
import pageRouter from './page';
import { mobileToH5 } from './h5RouterMap';

const { $t } = useLang( '@shining3d/app' );

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import( '@/views/home.vue' ),
		children: [...info, ...design, ...pageRouter],
		redirect: '/u/cases'
	},
	{
		path: '/p',
		name: 'pubArea',
		component: pubLay,
		children: pub
	},
	...DoctorConfirm,
	{
		path: '/privacyPolicy',
		name: 'privacyPolicy',
		component: () => import( '@/views/components/privacyPolicy.vue' )
	},
	{
		path: '/monthlyReport',
		name: 'monthlyReport',
		component: () => import( '@/views/dashboards/components/monthlyReport/index.vue' )
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import( '@/views/icons/index.vue' )
	},
	...reportNew,
	{
		path: '/report',
		name: 'report',
		component: () => import( '@/views/report/index.vue' )
	}
];

const router = createRouter({
	history: createWebHistory( '/' ),
	routes
});

export const whiteList = ['/reportNew', '/report/v2', '/privacyPolicy', '/reports', '/monthlyReport', '/icons', '/report'];
const titleWhiteList = ['/reportNew', '/report'];
router.beforeEach(( to, from, next ) => {
	if ( !from.name ) {
		mobileToH5( to );
	}
	const { t, bifrostJumpSignature, bifrostNodeJumpLink, ...query } = to.query;
	// 路由切换时，若发现query携带有token字段则删除
	if ( t || bifrostJumpSignature || bifrostNodeJumpLink ) {
		next({ path: to.path, query: query });
		return;
	}
	// oem不存在则获取query或默认index
	if ( !VueCookieNext.getCookie( 'oemURL' )){
		VueCookieNext.setCookie( 'oemURL', to.query?.oem_direct_domain || 'index', { expire: '1y', path: '/' });
	}
	const cachedViews = $store.state.cachedViews;
	if ( to.params && to.params.forceRefresh ) {
		cachedViews.push( to.name );
		$store.commit( 'setCachedViews', cachedViews );
	}
	// 页面标题，正畸报告不设置标题
	if ( !titleWhiteList.includes( to.path )) {
		const title = to.name ? _get( window, `app.i18n.@shining3d/app.title.${to.name}` ) : '';
		const oemTitle = _get( $store, 'state.sysParams.title' );
		document.title = title ? title + ( oemTitle ? '-' + oemTitle : '' ) : oemTitle || '';
	}
	if ( whiteList.includes( to.path ) || to.meta.noAuth) {
		next();
	} else
	// 如果未匹配到路由跳转info
		if ( VueCookieNext.getCookie( 'token' ) && to.matched.length === 0 ) {
			from.name ? next({ name: from.name }) : next( '/info' );
		} else
		// 对token的值进行判断，token不存在跳转登录
			if ( !VueCookieNext.getCookie( 'token' ) && !to.name.includes( 'oemDomainPage' )) {
				$exitBySearch( router.resolve({
					name: 'oemDomainPage',
					params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' },
					query: { dental_reditect: to.fullPath }
				}).href );
			} else if ( from.name === 'designCase' ) {
				// 对设计服务平台订单的页面的路由跳转进行判断下
				if ( sessionStorage.getItem( 'detanlDesignChooseGood' )) {
					XlMessageBox({
						title: $t( 'common.createOrder.confirmExit' ),
						type: 'warning',
						message: $t( 'common.createOrder.confirmExitMessage' ),
						confirmButtonText: $t( 'button.confirm' ),
						cancelButtonText: $t( 'button.cancel' )
					}).
						then(() => {
							sessionStorage.setItem( 'detanlDesignChooseGood', '' );
							next();
						}).
						catch(() => {
							console.log( 1 );
						});
				} else {
					next();
				}
			} else {
				next();
			}
	nextTick(() => {
		cachedViews.splice( cachedViews.indexOf( to.name, 1 ));
		$store.commit( 'setCachedViews', cachedViews );
	});
});

export default router;
