import axios from 'axios';
import { XlMessage, XlLogInAgain } from '@shining3d/ui-components';
import qs from 'qs';
import { VueCookieNext } from 'vue-cookie-next';
import { $exitBySearch } from '@/assets/common/common.js';
import { apiWhiteErrList } from './whiteList';
import _get from 'lodash/get';

// 错误信息处理
const errMapCommon = _get( window, 'app.i18n.@shining3d/app.apiErr.common' );
const errMap = _get( window, 'app.i18n.@shining3d/app.apiErr' );

const cookie = VueCookieNext;
const privateMap = ['orgIsMigrating', 'orgDataMigrationTaskExistErr']

let codeMsg = '';
let commonMsg = '';
const $errMsg = ( errStr, errCode ) => {
	// 关闭所有弹窗
	// XlMessage.closeAll();
	if ( !errCode ){
		return errStr;
	}
	let errJson = {};
	let privateErr = false
	try {
		errJson = JSON.parse( errStr );
		Object.keys( errJson ).forEach(( erk, i ) => {
			// 获取字段的错误code是否存在 解析如果为组件则获取组件包翻译
			if ( errCode.startsWith( '@shining3d/' )){
				codeMsg = _get( window.app.i18n, `${errCode}.${erk + errJson[ erk ]}` );
			} else {
				codeMsg = _get( errMap, `${errCode}.${erk + errJson[ erk ]}` );
			}
			// 字段错误不存在则获取公共错误
			!codeMsg && ( commonMsg = _get( errMapCommon, `${errJson[ erk ]}` ));

			if(!codeMsg && commonMsg && privateMap.includes( errJson[ erk ])) {
				// 专门处理数据迁移错误
				XlMessage({ message: commonMsg, type: 'error' });
				privateErr = true
			} else {
				// 赋值取字段错误/公共错误/默认错误
				errJson[ erk ] = codeMsg || commonMsg || errJson[ erk ];
			}
		});
		if ( !Object.keys( errJson ).length ) {
			errJson = ''; 
		}
	} catch ( e ) {
		// 解析如果为组件则获取组件包翻译
		if ( errCode.startsWith( '@shining3d/' )){
			errJson = _get( window.app.i18n, `${errCode}.${errStr}`, _get( errMapCommon, `${errStr}`, errStr ));
		} else {
			errJson = _get( errMap, `${errCode}.${errStr}`, _get( errMapCommon, `${errStr}`, errStr ));
		}
		XlMessage({ message: errJson, type: 'error' });
		errJson = '';
	}
	return !privateErr ? errJson : -1;
};

// 创建请求
export const service = axios.create({
	baseURL: window.app.baseURL,
	// baseURL: '',
	timeout: 1000 * 60,
	validateStatus: function ( status ) {
		return status >= 200 && status <= 400;
	}
});

// 请求拦截
service.interceptors.request.use(
	( config ) => {
		cookie.getCookie( 'token' ) &&
			( config.headers[ 'X-Auth-Token' ] = cookie.getCookie( 'token' ));
		if ( config.method === 'get' ) {
			config.paramsSerializer = function ( params ) {
				return qs.stringify( params, { arrayFormat: 'repeat' });
			};
		}
		return config;
	},
	( error ) => {
		console.error( error );
		return Promise.reject( error );
	}
);
const authErrFn = () => {
	const path = _get( window, 'location.pathname' );
	if ( path.includes( '/p/' )) {
		$exitBySearch();
	} else {
		const privacyTermsOfUse = _get( window, 'app.i18n.@shining3d/app.logout' );
		XlLogInAgain({
			title: privacyTermsOfUse?.accountExitPrompt || '账号退出提示',
			content: privacyTermsOfUse?.pleaseLogInAgain || '当前登录已失效，请重新登录',
			loginBtnTxt: privacyTermsOfUse?.logInAgain || '重新登录',
			loginCallback: () => {
				$exitBySearch();
			}
		});
	}
};
// 响应拦截
service.interceptors.response.use(
	( response ) => {
		const data = response.data;
		if ( response.status === 200 ) {
			if ( data && data.status === 'fail' ) {
				// cfrs失效则重新获取
				if ( data.result === 'authErr' ) {
					authErrFn();
					return Promise.reject( response );
				} else {
					return Promise.resolve( response );
				}
			}

			if ( data instanceof Blob ) {
				return response;
			}
			return Promise.resolve( response );
		} else if ( response.status === 400 ) {
			return Promise.resolve( response );
		} else {
			return Promise.reject( response );
		}
	},
	( error ) => {
		console.log( error );
		// ElMessage.closeAll();
		if ( error.message.includes( 'timeout' )) {
			// 判断请求异常信息中是否含有超时timeout字符串
			console.log( '错误回调', error );
			XlMessage({ type: 'error', message: _get( window.app.i18n, 'apiErr.common.requestTimeout' ) });
		}
		return Promise.reject( error );
	}
);

export function get(
	url,
	{ params, errCode, ...arg } = { params: {}, errCode: '' }
) {
	return service.get( url, { params }).then(( res ) => {
		// data存在并且请求失败，不为登录失效则处理报错信息
		if ( res.data && res.data.status === 'fail' && res.data.result !== 'authErr' ) {
			if ( apiWhiteErrList.find(( val ) => val.url === url && val.err.find(( item ) => item === res.data.result ))) {
				console.log( '白名单忽略 url:', url, ',err:', res.data.result );
			} else {
				res.data.result = $errMsg( res.data.result, errCode );
			}
		}
		return Promise.resolve( res.data );
	});
}
export function getConfig( url, config ) {
	return service.get( url, config ).then(( res ) => Promise.resolve( res.data ));
}
export function postConfig( url, config ) {
	return service.post( url, config ).then(( res ) => Promise.resolve( res.data ));
}
export function post( url, { errCode, data, ...arg }, config = {}) {
	return service.post( url, data, config ).then(( res ) => {
		// data存在并且请求失败，不为登录失效则处理报错信息
		if ( res.data && res.data.status === 'fail' && res.data.result !== 'authErr' ) {
			if ( apiWhiteErrList.find(( val ) => val.url === url && val.err.find(( item ) => item === res.data.result ))) {
				console.log( '白名单忽略:url', url, ',err:', res.data.result );
			} else {
				res.data.result = $errMsg( res.data.result, errCode );
			}
		}
		return Promise.resolve( res.data );
	});
}
const $postErrMsg = ( errStr, errCode ) => {
	// 关闭所有弹窗
	// XlMessage.closeAll();
	let errJson = {};
	try {
		errJson = JSON.parse( errStr );
		Object.keys( errJson ).forEach(( erk, i ) => {
			// 获取字段的错误code是否存在 解析如果为组件则获取组件包翻译
			if ( errCode.startsWith( '@shining3d/' )) {
				codeMsg = _get( window.app.i18n, `${errCode}.${erk + errJson[ erk ]}` );
			} else {
				codeMsg = _get( errMap, `${errCode}.${erk + errJson[ erk ]}` );
			}
			// 字段错误不存在则获取公共错误
			!codeMsg && ( commonMsg = _get( errMapCommon, `${errJson[ erk ]}` ));
			// 赋值取字段错误/公共错误/默认错误
			errJson[ erk ] = codeMsg || commonMsg || errJson[ erk ];
			if ( errJson[ erk ]){
				XlMessage({ message: errJson[ erk ], type: 'error' });
			}
		});
		if ( !Object.keys( errJson ).length ) {
			errJson = '';
		}
	} catch ( e ) {
		// 解析如果为组件则获取组件包翻译
		if ( errCode.startsWith( '@shining3d/' )) {
			errJson = _get( window.app.i18n, `${errCode}.${errStr}`, _get( errMapCommon, `${errStr}`, errStr ));
		} else {
			errJson = _get( errMap, `${errCode}.${errStr}`, _get( errMapCommon, `${errStr}`, errStr ));
		}
		XlMessage({ message: errJson, type: 'error' });
		errJson = '';
	}
	return errJson;
};
export function postWithErrMessage( url, { errCode, data, ...arg }, config = {}) {
	return service.post( url, data, config ).then(( res ) => {
		// data存在并且请求失败，不为登录失效则处理报错信息
		if ( res.data && res.data.status === 'fail' && res.data.result !== 'authErr' ) {
			if ( apiWhiteErrList.find(( val ) => val.url === url && val.err.find(( item ) => item === res.data.result ))) {
				console.log( '白名单忽略:url', url, ',err:', res.data.result );
			} else {
				res.data.result = $postErrMsg( res.data.result, errCode ) || res.data.result;
			}
		}
		return Promise.resolve( res.data );
	});
}

const http = {
	get,
	post,
	getConfig,
	postConfig,
	postWithErrMessage
};
export default http;
