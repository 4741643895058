import $moment from 'moment';
import commonApi from '@/api/common';
import { XlMessage, XlMessageBox } from '@shining3d/ui-components';
import { privacyUpdate } from '@shining3d/xl-login';
import info from '@/api/info';
import Login from '@/api/pub';
import InstitutionApi from '@/api/institutionSetting/index';
import store from '@/store';
import { VueCookieNext } from 'vue-cookie-next';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isPlainObject from 'lodash/isPlainObject';
import { nextTick } from 'vue';
import { useLang } from '@/hooks/lang';
import router from '@/router';
import { tooth } from '@shining3d/utils';
import bgImg from '@/assets/image/privacyUpdateBg.png';

const cc = VueCookieNext.getCookie( 'cc' ) || 'CN';

const { $t } = useLang( '@shining3d/app' );

// 地区日期差格式化转换
function $convertUTCDateToLocalDate( sd, format = 'YYYY-MM-DD HH:mm:ss' ) {
	// 如果 sd 是 moment 类型，先格式化为字符串
	if ( $moment.isMoment( sd )) {
		sd = sd.format( 'YYYY-MM-DD HH:mm:ss' );
	}
	if ( !sd || typeof sd !== 'string' || sd.includes( '1970' )) {
		return '';
	}
	// 时区差需要地区数据支撑
	let utcOffset = 0;
	const zones = store.state.countryList.find(( val ) => cc.toUpperCase() === val.id );
	if ( zones ) {
		try {
			utcOffset = JSON.parse( zones.timezones )[ 0 ].utcOffset;
		} catch ( error ) {
			console.log( error );
		}
	}
	return $moment.utc( sd ).utcOffset( utcOffset ).
		format( format );
}

// 地区日期差格式化转换（唯一区别 时区反向偏移）
function $convertLocalDateToUTCDate ( sd, format = 'YYYY-MM-DD HH:mm:ss' ) {
	if ( !sd || Object.keys( sd ).length < 1 ) {
		return '';
	}
	// 时区差需要地区数据支撑
	let utcOffset = 0;
	const zones = store.state.countryList.find(( val ) => cc.toUpperCase() === val.id );
	if ( zones ) {
		try {
			utcOffset = JSON.parse( zones.timezones )[ 0 ].utcOffset;
		} catch ( error ) {
			console.log( error );
		}
	}
	return $moment.utc( sd ).utcOffset( -utcOffset ).
		format( format );
}

// 获取国家码初始值
function $getPhoneArea( sd ) {
	const PhoneArea = store.state.countryList.find(( val ) => val.id === ( sd || cc ));
	return PhoneArea ? PhoneArea.phone : '86';
}

// 获取个人信息
async function $getInfo() {
	const res = await info.getInfo();
	if ( res.status === 'success' ) {
		// 更新创建时间转换
		res.result.lastOn = $convertUTCDateToLocalDate( res.result.lastOn );
		res.result.createOn = $convertUTCDateToLocalDate( res.result.createOn );
		// 上次登录地址拼接
		const country = _get( res.result, 'lastRegion.country', '' );
		const city = _get( res.result, 'lastRegion.city', '' );
		const area = _get( res.result, 'lastRegion.area', '' );
		res.result.region = ( country === 'xx' ? ' ' : country ) + ' ' + ( area === 'xx' ? ' ' : area ) + ' ' + ( city === 'xx' ? ' ' : city );
		// 更新个人信息存储
		store.commit( 'setUserInfo', { ...res.result } || {});
		return { ...res.result };
	} else {
		// 更新个人信息存储
		store.commit( 'setUserInfo', {});
		return {};
	}
}

// 本地存储
function $setLocalStorage( type, val ) {
	localStorage.setItem(( window.app.entryName || 'dental' ) + '-' + type, JSON.stringify( val ));
}

// 获取本地存储
function $getLocalStorage( type ) {
	if ( !type ) {
		return '';
	}
	let objtxt = localStorage.getItem(( window.app.entryName || 'dental' ) + '-' + type );
	try {
		objtxt = JSON.parse( objtxt );
	} catch ( error ) {
		console.log( objtxt );
	}
	return objtxt;
}

// 删除本地存储
function $removeLocalStorage( type ) {
	localStorage.removeItem(( window.app.entryName || 'dental' ) + '-' + type );
	localStorage.removeItem( type );
}

// 获取当前语言
function $lang() {
	const lang = VueCookieNext.getCookie( 'lang' );
	return lang;
}

// 语言转换
function $doLang( lang ) {
	if ( !lang || /^zh[-,,,_]{0,1}|^cn$/.test( lang )) {
		return 'zh-cn';
	}
	if ( /^en[-,,,_]{0,1}/.test( lang )) {
		return 'en-us';
	}
	if ( /^ja[-,,,_]{0,1}/.test( lang )) {
		return 'ja-jp';
	}
	if ( /^ko[-,,,_]{0,1}/.test( lang )){
		return 'ko-kr';
	}
	return lang;
}

// 语言转换2
function $rdoLang2( lang = $lang()) {
	if ( !lang || /^zh[-,,,_]{0,1}|^cn$/.test( lang )) {
		return 'zh_CN';
	}
	if ( /^en[-,,,_]{0,1}/.test( lang )) {
		return 'en_US';
	}
	if ( /^ja[-,,,_]{0,1}/.test( lang )) {
		return 'ja_JP';
	}
	return lang;
}

// lang转换
function $rdoLang( lang = $lang()) {
	if ( !lang || /^zh[-,,,_]{0,1}|^cn$/.test( lang )) {
		return 'cn';
	}
	if ( /^en[-,,,_]{0,1}/.test( lang )) {
		return 'en';
	}
	if ( /^ja[-,,,_]{0,1}/.test( lang )) {
		return 'ja';
	}
	return lang;
}

function $exitBySearch( redirectTo ) {
	!redirectTo && console.log( '$exitBySearch ： 退出登录' );
	VueCookieNext.removeCookie( 'token' );
	VueCookieNext.removeCookie( 'cc' );
	VueCookieNext.removeCookie( 'user_id' );
	VueCookieNext.removeCookie( 'uTitle' );
	$removeLocalStorage( 'perms' ); // 权限
	$removeLocalStorage( 'userInfo' ); // 用户信息
	$removeLocalStorage( 'factoryObjs' ); // 机构列表
	$removeLocalStorage( 'currentOrganization' ); // 选中机构
	$removeLocalStorage( 'defaultDentalOrderReceive' ); // 案例参数
	$removeLocalStorage( 'cancelPromptFlag' ); // 账户注销
	sessionStorage.removeItem( 'needAgreed' ); // 清除设计服务是否需要同意
	// 云平台处理，token存在跳过
	let redirectUrl = '';
	// 在/p路径则不存登陆后跳转路径，在其他页面存跳转路径
	if ( router.currentRoute.value.matched.some(( val ) => val.name === 'oemDomainPage' )){
		// search不清楚有何用，暂时留着
		redirectUrl = router.resolve({
			name: 'oemDomainPage',
			params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' }
		}).href + window.location.search;
	} else {
		// 增加dental_reditect参数，登陆后跳转
		redirectUrl = router.resolve({
			name: 'oemDomainPage',
			params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' },
			query: { dental_reditect: router.currentRoute.value.fullPath }
		}).href;
	}
	const redirectToUrl = redirectTo || redirectUrl;
	if ( window.location.href !== redirectToUrl ) { window.location.href = redirectToUrl; }
}

// 获取OEM配置
async function $getOEM() {
	// 获取OEM路径
	const oemURL = VueCookieNext.getCookie( 'oemURL' );
	const res = await Login.getOig( oemURL );
	const local = $rdoLang();
	if ( res.status === 'success' && res.result[ local ]) {
		// 更新OEM存储
		store.commit( 'setSysParams', { ...res.result[ local ] } || {});
		// 老项目适配
		$setLocalStorage( 'sysParams', { ...res.result });

		return res.result[ local ];
	} else {
		// 更新OEM存储
		store.commit( 'setSysParams', {});
		// 老项目适配
		$setLocalStorage( 'sysParams', {});
		return {};
	}
}

/**
 * 判断 oem是否真实存在， index 肯定存在
 * @param {*} domain
 * @returns
 */
async function $existOem( domain ) {
	const defaultOemUrl = 'index';
	if ( domain === defaultOemUrl ) {
		return true;
	}
	const res = await Login.existOem({ domain });
	if ( res.status === 'success' && res.result.toString() === 'false' ) {
		window.location.href = router.resolve({ name: 'oemDomainPage', params: { oemDomain: defaultOemUrl } }).href + window.location.search;
		return false;
	}
	return true;
}

/**
 * 替换favicon
 * @param {*} faviconUrl string
 */
function $setFavicon( faviconUrl ) {
	if ( !faviconUrl ) {
		return;
	}
	if ( document.querySelector( 'link[rel="icon"]' )) {
		document.querySelector( 'link[rel="icon"]' ).href = faviconUrl;
	} else {
		const link = document.createElement( 'link' );
		link.rel = 'icon';
		link.href = faviconUrl;
		document.head.appendChild( link );
	}
}

// 更新local里的 userInfo 中机构列表和权限列表 用于登录页刷新
function setOrgList( result, perms = '' ) {
	const orgList = [];
	if ( result && result.length ) {
		result.forEach(( v ) => {
			const obj = {};
			obj.isAdmin = v.isAdmin;
			obj.joinOn = v.joinOn;
			obj.memberExpire = v.memberExpired;
			obj.orgID = v.id;
			obj.orgName = v.name;
			obj.orgType = v.factoryType;
			obj.status = v.memberStatus;
			obj.tempState = v.tempState;
			orgList.push( obj );
		});
	}
	let userInfo = localStorage.getItem( 'userInfo' );
	try {
		userInfo = JSON.parse( userInfo );
		if ( userInfo ) {
			if ( orgList && orgList.length ) {
				userInfo.orgList = orgList;
			}
			if ( perms ) {
				userInfo.perms = perms;
			}
			localStorage.setItem( 'userInfo', JSON.stringify( userInfo ));
		}
	} catch ( error ) {
		console.log( 'localUserInfoErr', error );
	}
}

// 获取当前账号归属机构列表
const $getFactoryList = async ( type = { onlySelf: true, showMembers: true, lang: $rdoLang() }) => {
	const res = await info.getFactoryList( type );
	if ( res.status === 'success' && Array.isArray( res.result )) {
		// 处理创建更新时间
		res.result = res.result.map(( val ) => ({
			...val,
			memberCreateOn: $convertUTCDateToLocalDate( val.memberCreateOn ),
			memberChangeOn: $convertUTCDateToLocalDate( val.memberChangeOn )
		}));
		// 机构存在 && 存在已加入机构 && 默认机构全部为n && 非超管
		if ( res.result.length > 0 &&
			res.result.some(( val ) => val.memberStatus === 'active' ) &&
			res.result.every(( val ) => val.lastState === 'n' ) &&
			!$checkPerm( 'dental.admin_dental_order' )){
			res.result[ res.result.findIndex(( val ) => val.memberStatus === 'active' ) ].lastState = 'y';
		}
		// 存储机构列表存储
		store.commit( 'setFactoryObjs', [...res.result]);
		// 更新用户信息中的机构列表
		setOrgList( res.result );
		return [...res.result];
	} else {
		// 不存在直接清空机构列表和机构选择
		store.commit( 'setFactoryObjs', []);
		store.commit( 'setFactorySelect', {});
		return [];
	}
};

// 获取机构所属的节点列表
const $getFactoryNodeList = async ( orgID ) => {
	if ( !orgID ) {
		return [];
	}
	const res = await commonApi.getNodes({ orgID });
	if ( res.status === 'success' && Array.isArray( res.result.nodes )) {
		const nodes = res.result.nodes.map(( val ) => ({
			...val,
			value: val.matchNode,
			isNewInfo: _get( window, 'app.global.currentNode', '' ) === val.matchNode ? false : val.isNewInfo,
			name: $t( 'nodes.' + val.matchNode )
		}));
		// 存储机构节点列表
		store.commit( 'setFactoryNodeList', [...nodes]);
		return [...nodes];
	} else {
		// 不存在直接清空节点列表
		store.commit( 'setFactoryNodeList', []);
		return [];
	}
};

const $getFactoryDetail = async ( orgID ) => {
	if ( !orgID ) {
		return [];
	}
	const res = await InstitutionApi.getInfo({ tid: orgID });
	if ( res.status === 'success' ) {
		return { ...res.result };
	} else {
		// 更新个人信息存储
		return {};
	}
};

// 获取所有节点列表
const $getNodeList = async () => {
	const res = await Login.nodeList();
	const nodes = res.result.nodes;
	if ( res.status === 'success' && !_isEmpty( nodes ) && _isArray( nodes )) {
		const nodeList = nodes.map(( val ) => ({
			value: val.matchNode,
			label: $t( 'nodes.' + val.matchNode ),
			domain: val.domain
		}));
		// const currentHost = window.location.origin;
		// const userNode = nodeList.find(( item ) => item.domain === currentHost )?.value;
		// if ( userNode && _get( window, 'app.global.currentNode', '' ) !== userNode ){
		VueCookieNext.setCookie( 'userNode', _get( window, 'app.global.currentNode', '' ), { expire: '1y', path: '/' });
		// }
		// 存储节点列表
		store.commit( 'setNodeList', [...nodeList]);
		return nodeList;
	} else {
		// 不存在直接清空节点列表
		store.commit( 'setNodeList', []);
		return [];
	}
};
// 获取当前账号权限列表
const $getPermList = async ( type = {}) => {
	const res = await info.getPermList( type );
	if ( res.status === 'success' && res.result && res.result.length > 0 ) {
		// 更新账号权限列表
		store.commit( 'setPermsList', [...res.result]);
		// 更新用户信息中的权限列表
		setOrgList( '', res.result );
		return [...res.result];
	} else {
		// 更新账号权限列表
		store.commit( 'setPermsList', []);
		return [];
	}
};
async function $getSettingList({ type, lang }) {
	const res = await commonApi.getSettingList( type );
	if ( res.status === 'success' && res.result && res.result.length > 0 ) {
		return res.result.map(( v ) => {
			const obj = {};
			obj.value = v.keyName;
			try {
				obj.label = JSON.parse( v.other )[ lang ];
			} catch ( error ) {
				obj.label = '';
			}
			return obj;
		});
	} else {
		return [];
	}
}

// 本地存储
export function $setNoProjectLocalStorage( type, val ) {
	localStorage.setItem( type, JSON.stringify( val ));
}

// 获取本地存储
export function $getNoProjectLocalStorage( type ) {
	if ( !type ) {
		return '';
	}
	let objtxt = localStorage.getItem( type );
	try {
		objtxt = JSON.parse( objtxt );
	} catch ( error ) {
		console.log( objtxt );
	}
	return objtxt;
}

// 删除本地存储
export function $removeNoProjectLocalStorage( type ) {
	localStorage.removeItem( type );
}

// 获取当前加入的归属机构列表(默认为已加入状态)
function $factoryList( type = 'active' ) {
	return store.state.factoryObjs ? store.state.factoryObjs.filter(( val ) => val.memberStatus === type ) : [];
}

function $isSafari() {
	const ua = navigator.userAgent.toLowerCase();
	return Boolean( ua.match( /version\/([\d.]+).*safari/ ));
}

// 获取国家，国家码列表
async function $getCountry() {
	const res = await commonApi.getCountry();
	if ( res.status === 'success' ) {
		const countryList = res.result.map(( val ) => ({
			...val,
			value: val.id,
			label: val.name === val.native ? val.name : val.name + ' ' + val.native
		}));
		const phoneAreaList = [];
		res.result.forEach(( val ) => {
			if ( val.phone.split( ',' ).length === 1 ) {
				phoneAreaList.push({
					label: val.name === val.native ? `+${val.phone} ${val.name}` : `+${val.phone} ${val.name} ${val.native}`,
					value: `${val.phone}-${val.id}`
				});
			} else {
				val.phone.split( ',' ).map(( item ) => {
					phoneAreaList.push({
						label: val.name === val.native ? `+${item} ${val.name}` : `+${item} ${val.name} ${val.native}`,
						value: `${item}-${val.id}`
					});
				});
			}
		});
		store.commit( 'setPhone', [...phoneAreaList] || []);
		store.commit( 'setCountry', [...countryList] || []);
		return [...res.result];
	} else {
		return [];
	}
}

// 设置当前语言
function $setLang( val ) {
	if ( VueCookieNext.getCookie( 'user_id' )) {
		commonApi.
			settingsLang({ userId: VueCookieNext.getCookie( 'user_id' ), lang: val }).
			then(() => {
				VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
				window.location.reload();
			});
	} else {
		VueCookieNext.setCookie( 'lang', val, { expire: '3d', path: '/' });
		window.location.reload();
	}
}

// 地址拼接
function $getCdnUrl( imgObj, isImg ) {
	let cdnUrl = '';
	if ( !imgObj || !imgObj.bucket ) {
		return cdnUrl;
	}
	// 默认值修改
	const cdnUrlConfig = _get( store.state, 'global.cdnUrl', {});
	const cloud = _get( store.state, 'global.cloud', 'aliyun' );
	if ( !isImg ) {
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.dfsID;
	}
	if ( cloud === 'aliyun' ) {
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.dfsID;
	}
	if ( cloud === 'aws' ) {
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + '100xAUTO/' + imgObj.dfsID;
	}
	return cdnUrl;
}

// msg报错处理
function $formErr( res, form, type = 'form' ) {
	if ( typeof form === 'string' ) {
		XlMessage({ type: 'error', message: res });
	} else if ( _isPlainObject( form ) && _isPlainObject( res )) {
		for ( const key in form ) {
			form[ key ] = '';
		}
		nextTick(() => {
			Object.keys( res ).forEach(( val ) => {
				if ( type === 'form' ) {
					form[ val ] = res[ val ];
				} else {
					XlMessage({ type: 'error', message: res[ val ] });
				}
			});
		});
	}
}

// 退出登录
function $logout( redirectTo ) {
	XlMessageBox({
		title: $t( 'logout.title' ),
		message: $t( 'logout.desc' ),
		cancelButtonText: $t( 'button.cancel' ),
		confirmButtonText: $t( 'button.confirm' )
	}).
		then(() => {
			// 登出时使后端token失效
			const userId = $getLocalStorage( 'userInfo' )?.userID;
			commonApi.userLogout({ logoutType: 'dentalCloudSite', userId }).then(( res ) => {
				if ( res.status === 'success' ) {
					$exitBySearch( redirectTo || router.resolve({ name: 'oemDomainPage', params: { oemDomain: VueCookieNext.getCookie( 'oemURL' ) || 'index' } }).href );
				}
			});
		}).
		catch(( e ) => {
			console.log( 'catch', e );
		});
}

const $checkPerm = ( perm ) => {
	const allperms = store.state.permsList || $getLocalStorage( 'perms' ) || [];
	return $hasOnePerm( allperms, perm );
};
const $hasOnePerm = ( perms, perm ) => {
	// 权限点不存在可显示
	if ( _isEmpty( perm )) {
		return true;
	}
	// 权限点存在但列表不存在则不显示
	if ( _isEmpty( perms )) {
		return false;
	}
	// 只要有一个权限点存在，则显示
	if ( Array.isArray( perm )) {
		for ( const k of perm ) {
			if ( perms.indexOf( k ) > -1 ) {
				return true;
			}
		}
	}
	// 单个权限点存在则显示
	if ( typeof perm === 'string' ) {
		if ( perms.indexOf( perm ) > -1 ) {
			return true;
		}
	}
	// 权限列表不为空，权限点不为空，权限点不存在于权限列表中
	return false;
};

/**
 * 复制内容到剪切板
 * @param {String} text
 */
const $copyText = ( text ) => {
	// 动态创建textarea标签
	const textarea = document.createElement( 'textarea' );
	// 将该textarea设为readonly防止ios下自动唤起键盘，同时将textarea移出可视区域
	textarea.readOnly = 'readonly';
	textarea.style.position = 'absolute';
	textarea.style.left = '-9999px';
	// 将要copy的值赋值给textarea标签的value属性
	textarea.value = text;
	// 将textarea插入到body中
	document.body.appendChild( textarea );
	// 选中值
	textarea.select();
	// 将选中的值复制并赋值给result
	const result = document.execCommand( 'Copy' );
	document.body.removeChild( textarea );
	if ( result ) {
		return true;
	} else {
		return false;
	}
};
/**
 * 数据列表，请求下一页数据arrayB时，arrayB中的数据可能会和已展示的数据列表arrayA中有重复，从而去重并入到arrayA中
 * 前提：arrayB的数据和arrayA的数据，顺序没有变更。
 * @param {Array} arrayA 需要合并的数组
 * @param {Array} arrayB 需要被合并的数组
 * @param {String} key 每条数据的唯一识别key
 * @returns
 */
const $listDeduplication = ( arrayA, arrayB, key = 'id' ) => {
	const length1 = arrayA.length;
	const length2 = arrayB.length;
	let k = 0;
	for ( let i = 1;i <= Math.min( length1, length2 );i = i + 1 ) {
		if ( arrayA[ length1 - i ][ key ] === arrayB[ 0 ][ key ]) {
			k = i;
			break;
		}
	}
	if ( k === Math.min( length1, length2 ) + 1 ) {
		return arrayA.concat( arrayB );
	} else {
		return arrayA.concat( arrayB.slice( k ));
	}
};
/**
 * 下载文件
 * @param {String} url 文件下载地址
 * @param {String} name 文件名
 * @returns
 */
const $downloadFile = ( url, name ) => {
	if ( !url ) {
		return;
	}
	if ( name ) {
		fetch( url ).
			then(( response ) => response.blob()).
			then(( blob ) => {
				const link = document.createElement( 'a' );
				const blobUrl = URL.createObjectURL( blob );
				link.style.display = 'none';
				link.href = blobUrl;
				link.download = name;
				link.click();
				URL.revokeObjectURL( blobUrl );
			});
	} else {
		const link = document.createElement( 'a' );
		link.target = '_blank'; // 在新窗口中打开文件
		link.href = url;
		// link.href = '/download?url=' + encodeURIComponent( url );
		document.body.appendChild( link );
		link.click();
		document.body.removeChild( link );
	}
};
/**
 * 新窗口打开
 * @param {String} url 新窗口地址
 * @param {String} target 打开方式
 * @returns
 */
const $openUrl = ( url, target = '_blank' ) => {
	if ( !url ) {
		return;
	}
	const link = document.createElement( 'a' );
	link.target = target;
	link.style.display = 'none';
	link.href = url;
	document.body.appendChild( link );
	link.click();
	document.body.removeChild( link );
};
/**
 * 获取图片制定宽度和高度的缩略图，仅支持aliyun的oss储存的图片
 * 针对aws的服务图片，缩略图暂时用100尺寸的图片
 * @param {图片对像} imgObj
 * @param {缩放之后的宽度} width
 * @param {缩放之后的高度} height
 * @returns
 */
const $ossImageByWidthAndHeight = ( imgObj ) => {
	let cdnUrl = '';
	if ( !imgObj || !imgObj.bucket ) {
		return cdnUrl;
	}
	const w = imgObj.w || 100;
	const h = imgObj.h;
	const m = imgObj.m;
	const cdnUrlConfig = _get( store.state, 'global.cdnUrl', {});
	const cloud = _get( store.state, 'global.cloud', 'aliyun' );
	if ( !cdnUrlConfig[ imgObj.bucket ]){
  	return '';
	}
	if ( cloud === 'aliyun' ) {
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.dfsID + `?x-oss-process=image/resize${m ? ',m_' + m : ''},w_${w}${h ? ',h_' + h : ''}`;
	} else if ( cloud === 'aws' ) {
		// 暂时用固定的尺寸，待后续调研
		cdnUrl = cdnUrlConfig[ imgObj.bucket ] + imgObj.w + 'xAUTO/' + imgObj.dfsID;
	}
	return cdnUrl;
};
// 获取当前的归属机构列表
function $factoryAllList() {
	return store.state.factoryObjs;
}
const $getTimeHMS = ( second ) => {
	// 转换为式分秒
	let h = 0;
	let m = 0;
	let s = 0;
	if ( Number( second ) > -1 ){
		const hour = Math.floor( Number( second ) / 3600 );
		const min = Math.floor( Number( second ) / 60 ) % 60;
		const sec = parseInt( Number( second ) % 60 );
		h = hour < 10 && hour !== 0 ? '0' + hour : hour;
		m = min < 10 && min !== 0 ? '0' + min : min;
		s = sec < 10 && sec !== 0 ? '0' + sec : sec;
	}
	return { h, m, s };
};

const $readFileBase64 = ( file ) => new Promise(( resolve, reject ) => {
	const reader = new FileReader();
	reader.onload = () => {
		resolve( reader.result );
	};
	reader.onerror = reject;
	reader.readAsDataURL( file );
});

const $decodeHtml = ( html ) => {
	let s = html;
	const HTML_DECODE = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&nbsp;': ' ',
		'&quot;': '"',
		'&copy;': ''

		// Add more
	};
	const REGX_HTML_DECODE = /&\w+;|&#(\d+);/g;

	s = s !== undefined ? s : '';
	if ( s === '' ) {
		return s;
	}
	return typeof s !== 'string' ? s : s.replace(
		REGX_HTML_DECODE,
		function( $0, $1 ){
			let c = HTML_DECODE[ $0 ];
			if ( c === undefined ){
				// Maybe is Entity Number
				if ( !isNaN( $1 )){
					c = String.fromCharCode( $1 === 160 ? 32 : $1 );
				} else {
					c = $0;
				}
			}
			return c;
		}
	);
};

const $pluginChange = ( plugin ) => {
	const pluginMap = new Map([['mouthScan', 'newaoralscan_domestic'], ['faceScan', 'facecan'], ['deskScan', 'deskScan'], ['orthodonticSimulation', 'OrthoSim']]);
	const pluginMap2 = new Map([['mouthScan', 'newaoralscan_international'], ['faceScan', 'facecan'], ['deskScan', 'deskScan'], ['orthodonticSimulation', 'OrthoSim']]);
	if ( cc === 'CN' ) {
		return pluginMap.get( plugin );
	} else {
		return pluginMap2.get( plugin );
	}
};

const $getBlobByUrl = ( url ) => new Promise(( resolve, reject ) => {
	const xhr = new XMLHttpRequest();
	xhr.open( 'get', url, true );
	xhr.responseType = 'blob';
	xhr.onload = function () {
		if ( this.status === 200 ) {
			resolve( this.response );
		} else {
			reject( new Error( 'getBlobByUrl: network is error' ));
		}
	};
	xhr.send();
	xhr.onerror = () => {
		reject( new Error( 'getBlobByUrl: url is error' ));
	};
});
const $getToothSingType = ( type ) => {
	if ( type && type.includes( 'SDNT_' )) {
		return type.slice( 5 );
	} else {
		return type || 'FDI';
	}
};

const $transToothNumber = ( num, toType = 'FDI' ) => {
	if ( isNaN( num )) {
		return num;
	}
	// eslint-disable-next-line no-param-reassign
	toType = $getToothSingType( toType );
	if ( toType === 'Universal' ) {
		return tooth.getToothNumber( num, 'FDI', toType );
	}
	return num;
};

const $showToothSign = ( type ) => {
	if ( type === 'Universal' ) {
		return 'UN';
	} else {
		return 'FDI';
	}
};

const $getTags = ( tag, text, lang ) => {
	const tagList = tag.split( ',' );
	let txt = '';
	const betweenObj = {
		en: ' and ',
		cn: '和',
		ja: 'と'
	};
	const punctObj = {
		en: ', ',
		cn: '、',
		ja: '、'
	};
	const between = betweenObj[ lang ] || '和';
	const tagLen = tagList.length;
	const punct = punctObj[ lang ] || '、';
	const punctLen = punct.length;
	if ( tagLen > 1 ) {
		tagList.forEach(( v, i ) => {
			if ( i === tagLen - 2 ) {
				txt += $t( text + v ) + between;
			} else {
				txt += $t( text + v ) + punct;
			}
		});
		txt = txt.substring( 0, txt.length - punctLen );
	} else if ( tag ) {
		txt = $t( text + tag );
	}
	return txt;
};

function $resetFileName( name ) {
	return name?.toString().replace( /[<>:"/\\\\|?*]/g, '_' );
}
const $jsonParse = ( item, defaultVal = {}) => {
	try {
		return JSON.parse( item );
	} catch ( error ) {
		return defaultVal;
	}
};
/**
 * 签到 得 积分
 * @returns
 */
function $pointsSignIn() {
	const userId = VueCookieNext.getCookie( 'user_id' );
	const stoKey = `pointsSignInTime-${userId || ''}`;
	const pointsSignInTime = $getLocalStorage( stoKey );
	const data = $moment( new Date()).format( 'YYYY-MM-DD' );
	if ( pointsSignInTime && data === pointsSignInTime ) {
		return;
	}
	commonApi.pointsSignIn().then(( res ) => {
		if ( res.status === 'success' || res.result === 'duplicate' ) {
			$setLocalStorage(
				stoKey,
				data
			);
		}
	});
}
// 判断当时区号是否有供应商/当前域名是否在域名黑名单
async function $isUsernameDisabled( username, phoneCode ) {
	let msg = '';
	if ( username.includes( '@' )) {
		await info.getDisableDomainList().then(( res ) => {
			if ( res.status === 'success' ){
				const disableDomainList = res.result || [];
				if ( disableDomainList.some(( i ) => i === username.split( '@' )[ 1 ])){
					// msg = '请更换邮箱，您输入的邮箱目前无法支持';
					msg = $t( 'rules.mailDomainDisableErr' );
				}
			} else {
				$formErr( res.result, {}, 'msg' );
			}
		});
	} else {
		// console.log( store.state.countryList, 'store.state.countryList=====', phoneCode, phoneCode.split( '-' )[ 1 ], store.state.countryList.filter(( i ) => i.id === phoneCode.split( '-' )[ 1 ]));
		const flag = store.state.countryList.find(( i ) => i.id === phoneCode.split( '-' )[ 1 ])?.isProvider === 'y';
		if ( !flag ){
			// msg = '该地区仅支持邮箱验证';
			msg = $t( 'rules.phoneAreaDisableErr' );
		}
	}
	return msg;
}

const $sum = ( arr ) => arr.reduce( function ( total, value ) {
	return total + value;
}, 0 );

function $privacyUpdate( status = true ) {
	const isLogin = window.location.pathname === '/' || window.location.pathname.startsWith( '/p/' );
	// 登录页不提示
	if ( isLogin && status ) {
		return;
	}
	const { agreedInfo } = $getLocalStorage( 'userInfo' );
	privacyUpdate({
		productKey: window.app.entryApiName?.toLowerCase(),
		agreedInfo,
		bgImg: bgImg,
		callback: ( flag ) => {
			if ( !flag ) {
				$exitBySearch();
			}
		}
	});
}

// A 标签下载
const $downloadByAlink = ( blob, filename = '' ) => {
	const blobUrl = blob instanceof Blob ? window.URL.createObjectURL( blob ) : blob;
	const a = document.createElement( 'a' );
	a.href = blobUrl;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL( blobUrl );
};

const judgingLanguage = ( str ) => {
	if ( /^[A-Za-z\s]+$/.test( str )) {	// 英文字符全匹配
		return 'en';
	} else if ( /^[\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF]+$/.test( str )) {	// 日文字符全匹配
		return 'ja';
	} else if ( /[\u4E00-\u9FFF\u3400-\u4DBF]/.test( str )) {	// 有一个中文字符则认为是中文
		return 'cn';
	} else {
		return 'other';
	}
};

// 姓名脱敏1 (汉字按字符,非汉字按空格分割,保留首尾)
const $nameMarking = ( names ) => {
	if ( names ) {
		let nameFix = names.toString();
		if ( judgingLanguage( nameFix ) === 'cn' ) { // 全中文字符先去空格再做字符分割重新以空格拼接
			nameFix = nameFix.replace( /\s*/g, '' ).split( '' ).
				join( ' ' );
		}
		const words = nameFix.trim().replace( /\s+/g, ' ' ).
			split( ' ' ); // 去除首尾空格，并将多个空格合并为一个，再以空格分割成单词数组
		if ( words.length === 1 ) {
			return words[ 0 ].slice( 0, 1 ) + '*'.repeat( words[ 0 ].length - 1 ); // 如果只有一个单词，保留首字母其余用*代替
		} else if ( words.length === 2 ) {
			return words[ 0 ] + ' *'; // 如果有两个单词，保留第一个单词，后面的单词用 * 代替
		} else {
			return words[ 0 ] + ' *'.repeat( words.length - 2 ) + ' ' + words[ words.length - 1 ]; // 如果有三个以上的单词，保留首尾单词，中间的单词用 * 代替
		}
	} else {
		return '*';
	}
};

// 姓名脱敏2
const $desensitizeName = ( names ) => {
	if ( names ) {
		let nameFix = names.toString();
		if ( judgingLanguage( nameFix ) === 'cn' ) { // 全中文字符先去空格再做字符分割重新以空格拼接
			nameFix = nameFix.replace( /\s*/g, '' ).split( '' ).
				join( ' ' );
		}
		const words = nameFix.trim().replace( /\s+/g, ' ' ).
			split( ' ' ); // 去除首尾空格，并将多个空格合并为一个，再以空格分割成单词数组
		if ( words.length === 1 ) {
			return '*'; // 如果只有一个单词，直接输出 *
		} else if ( words.length === 2 ) {
			return words[ 0 ] + ' *'; // 如果有两个单词，保留第一个单词，后面的单词用 * 代替
		} else {
			return words[ 0 ] + ' *'.repeat( words.length - 2 ) + ' ' + words[ words.length - 1 ]; // 如果有三个以上的单词，保留首尾单词，中间的单词用 * 代替
		}
	} else {
		return '';
	}
};
// 手机号脱敏
const $desensitizePhone = ( phoneNumber, phoneArea ) => {
	if ( /^\d+$/.test( phoneNumber )) {
		// 对纯数字手机号进行脱敏处理
		if ( phoneArea && phoneArea === '86' ) {
			// 国内手机脱敏
			return phoneNumber.replace( /(\d{3})\d{4}(\d{4})/, '$1****$2' ); // 返回脱敏后的号码
		} else if ( phoneNumber.length > 6 ) {
			// 国际手机脱敏
			const visibleDigits = phoneNumber.length - 6;
			const masked = phoneNumber.substr( 0, visibleDigits ).replace( /\d/g, '*' ) + phoneNumber.substr( visibleDigits );
			return masked; // 返回脱敏后的号码
		} else {
			return phoneNumber; // 如果手机号长度不足6位，则不进行脱敏处理
		}
	} else {
		return '';
	}
};
// 邮箱脱敏
const $desensitizeEmail = ( email ) => {
	const atIndex = email.indexOf( '@' );
	if ( atIndex > 0 ) {
		const maskedPart = email.charAt( 0 ) + email.substr( 1, atIndex - 2 ).replace( /./g, '*' ) + email.charAt( atIndex - 1 ); // 保留第一个字母，并用星号替换邮箱用户名的其余部分
		const domain = email.substr( atIndex ); // 获取邮箱域名部分
		return maskedPart + domain; // 返回脱敏后的邮箱地址
	} else {
		return '';
	}
};

// 空间容量转换
export const formatBytes = ( len = 0, precision = 2 ) => {
	if ( Number( len ) === 0 ) {
		return '0 B';
	} else if ( !Number( len )){
		return len;
	}
	const step = 1024;
	const unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const order = Math.floor( Math.log( len ) / Math.log( step ));
	return parseFloat(( len / Math.pow( step, order )).toFixed( precision )) + unit[ order ];
};

const $imageUrl = ( imagePath ) => new URL( `../image/${imagePath}`, import.meta.url ).href;

const $isValidJSON = ( str ) => {
	try {
		JSON.parse( str );
		return true;
	} catch ( e ) {
		return false;
	}
};


export {
	$pointsSignIn,
	$convertUTCDateToLocalDate,
	$convertLocalDateToUTCDate,
	$getInfo,
	$getCountry,
	$getPhoneArea,
	$doLang,
	$getOEM,
	$setFavicon,
	// $getOemColor,
	$rdoLang,
	$rdoLang2,
	$lang,
	$setLang,
	$formErr,
	$decodeHtml,
	$setLocalStorage,
	$getLocalStorage,
	$removeLocalStorage,
	$getFactoryList,
	$factoryList,
	$checkPerm,
	$getPermList,
	$exitBySearch,
	$getCdnUrl,
	$logout,
	$getSettingList,
	$copyText,
	$listDeduplication,
	$downloadFile,
	$openUrl,
	$factoryAllList,
	$ossImageByWidthAndHeight,
	$getTimeHMS,
	$readFileBase64,
	$pluginChange,
	$getToothSingType,
	$transToothNumber,
	$showToothSign,
	$getTags,
	$isSafari,
	$resetFileName,
	$getBlobByUrl,
	$sum,
	$getNodeList,
	$getFactoryNodeList,
	$existOem,
	$privacyUpdate,
	$jsonParse,
	$isUsernameDisabled,
	$downloadByAlink,
	$nameMarking,
	$desensitizePhone,
	$desensitizeEmail,
	$desensitizeName,
	$getFactoryDetail,
	$imageUrl,
	$isValidJSON
};
